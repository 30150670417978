import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { experienceActions } from "src/db/dbActions";

/** Only display bottom nav on these pages */
const allowedRoutes = ["/profile", "/created-experiences", "/bookings"];
/**
 * Check if the user is on a mobile device
 */
const is_mobile = navigator.userAgentData
  ? navigator.userAgentData.mobile
  : navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/BlackBerry/i);

class BottomNav extends React.Component {
  render() {
    const { user } = this.props;
    if (is_mobile || !allowedRoutes.includes(window.location.pathname)) {
      return null;
    }
    return (
      <Navbar id="navbarBottom" bg="light" expand="lg" fixed="bottom" className="space-between if-fixed-footer mx-auto">
        <Button
          size="md"
          variant="primary"
          onClick={() =>
            experienceActions
              .createNewExperience({
                scout: user,
                scoutId: user.id,
                type: "manual",
                taxCategory: "19000",
              })
              .then((res) => {
                window.location.pathname = `/edit/${res.id}`;
              })
          }
          className="scouted-btn scouted-btn-blue scouted-btn-sep icon-plus-square three-across"
        >
          NEW
        </Button>
        <Button
          size="md"
          variant="primary"
          onClick={() => (window.location.pathname = "/created-experiences")}
          className="scouted-btn scouted-btn-blue scouted-btn-sep icon-edit three-across"
        >
          EDIT
        </Button>
        <Button
          size="md"
          variant="primary"
          onClick={() => (window.location.pathname = "/bookings")}
          className="scouted-btn scouted-btn-blue scouted-btn-sep icon-search three-across"
        >
          BOOKINGS
        </Button>
      </Navbar>
    );
  }
}

export default BottomNav;
