import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "./Footer";
import Header from "./Header";
import BottomNav from "./BottomNav";
import { userActions } from "src/db/dbActions";
import { authService } from "src/api/apiActions";
import { Hub } from "@aws-amplify/core";
import "src/styles/Layout.css";

const hideFooterPages = [
  "/single-location",
  "/multiple-location",
  "/real-time-location",
  "/manual-location",
  "/terms-and-conditions",
  "/tos-agreement",
];

class Layout extends React.Component {
  constructor() {
    super();
    this.state = { user: null };
  }

  componentDidMount() {
    authService
      .isAuthenticated()
      .then((res) => {
        userActions
          .getCurrentUser({ email: res.attributes.email })
          .then((currUser) => {
            this.setState({ user: currUser[0] });
          })
          .catch((err) => err);
      })
      .catch((e) => e);
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signOut") {
        this.setState({ user: null });
        window.localStorage.setItem("logged_in", false);
      }
    });
  }

  render() {
    const { user } = this.state;
    const hideFooter =
      !hideFooterPages.includes(window.location.pathname) && !window.location.pathname.endsWith("/edit");
    return (
      <Container fluid className="p-0">
        <Header {...this.props} />
        <Container fluid className="py-0 px-0">
          {this.props.children}
        </Container>
        {hideFooter && <Footer />}
        {user && hideFooter && user.isInterested && (
          <>
            <BottomNav {...this.props} user={user} />
          </>
        )}
      </Container>
    );
  }
}

export default Layout;
