import React from "react";
import MetaTags from "src/components/layout/MetaTags";
import { NOT_FOUND } from "src/constants";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
class Maintenance extends React.Component {
  render() {
    return (
      <>
        <MetaTags
          title="Maintenance Mode - Sorry, the site has entered maintenance mode"
          description="Sorry to report the site has entered maintenance mode, please check back soon!"
          url={`${window.location.origin}`}
          image={NOT_FOUND}
        />
        <Container id="FooterPages" className="text-md-start py-5">
          <Row className="justify-content-center mb-5">
            <Col>
              <Image
                height={100}
                src="https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1617905620/assets/scouted-logo/logo-text_whtbg_tn2nnk.png"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <h1>We are so sorry...</h1>
              <h2>We are in maintenance mode!</h2>
              <h3>Please check back again soon...</h3>
              <h4>
                We've found some bugs and we've had to take the site offline for a short period while we fix them.
              </h4>
              <p>
                Meanwhile we are consuming gallons of coffee and moving our fingers very quickly while we do whatever it
                takes to get the site live again. We'll have it back up as soon as possible.
              </p>
            </Col>
            <Col sm={12} md={12} lg={6} className="text-center ps-3 py-3">
              <Image
                alt="All you need is a phone and a desire to take a path less traveled!"
                className="img-fluid hide-mobile"
                src={NOT_FOUND}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Maintenance;
