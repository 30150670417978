import { API } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
/**
 * Functions used to communicate with Sterling api
 */
export const sterlingService = {
  /**
   * Request an authentication token from Sterling allowing for communication with their api
   *
   * @returns {String} Sterling authentication token
   */
  auth: () => API.post("lambdaApi", "/sterling/auth"),
  /**
   * Create a new client in the Sterling database
   *
   * @param {Object} body {
   * token: String,
   * {
   *   address: String,
   *   clientReferenceId: String,
   *   dob: String,
   *   email: String,
   *   givenName: String,
   *   familyName: String,
   *   phone: String,
   *   ssn: String,
   * }}
   * @returns {String} New client id
   */
  createClient: (body) => API.post("lambdaApi", "/sterling/create-client", { body }),
  /**
   * Create a new screening for client through Sterling
   *
   * @param {String} body {
   * token: String,
   * {
   *   candidateId: String,
   *   packageId: String
   * }}
   * @returns {Object} Screening data
   */
  createScreening: (body) => API.post("lambdaApi", "/sterling/screening", { body }),
  /**
   * Retrieve screening result from Sterling
   *
   * @param {Object} body {
   *   id: String,
   *   token: String
   * }
   * @returns {Object} Screening result
   */
  screeningResult: (body) => API.post("lambdaApi", "/sterling/screening/result", { body }),
  /**
   * Retrieve client details from Sterling
   *
   * @param {String} body {
   * token: String,
   * {
   *   candidateId: String,
   *   packageId: String
   * }}
   * @returns {Object} Screening data
   */
  getCandidate: (body) => API.post("lambdaApi", "/sterling/candidate", { body }),
  /**
   * Check if client screening status has changed
   *
   * @param {String} body {
   * token: String,
   * {
   *   id: String,
   *   token: String
   * }}
   * @returns {Object} Screening data
   */
  screeningComplete: (body) =>
    API.post("lambdaApi", "/sterling/update", { body: { ...body, url: window.location.origin } }),
};

/**
 * Functions used to communicate with email api
 */
export const contactService = {
  /**
   * General email to contact Scouted LLC
   *
   * @param {Object} body {
   *   name: String,
   *   dept: String,
   *   email: String,
   *   phone: String,
   *   msg: String
   * }
   * @returns Success / Fail response
   */
  contact: (body) => API.post("lambdaApi", "/contact", { body }),
  tellAFriend: (body) => API.post("lambdaApi", "/tell-a-friend", { body }),
  subscriptionConfirmation: (body) => API.post("lambdaApi", "/subscription-confirmation", { body }),
  zeroAvailability: (body) => API.post("lambdaApi", "/zero-availability", { body }),
  /**
   * Purchase confirmation email when payment successfully recieved
   *
   * @param {Object} body {
   *   name: String,
   *   experience: Object,
   *   email: String,
   *   startDate: String,
   *   MESSAGE_TIMING: Int
   * }
   * @returns Success / Fail response
   */
  purchaseConfirmation: (body) =>
    API.post("lambdaApi", "/purchase-confirmation", { body: { ...body, url: window.location.origin } }),
  /**
   * Purchase failure email when payment is unsuccessful
   *
   * @param {Object} body {
   *   email: String,
   *   name: String,
   *   title: String,
   *   startDate: String,
   *   expLink: String
   * }
   * @returns Success / Fail response
   */
  purchaseFailed: (body) =>
    API.post("lambdaApi", "/purchase-failed", { body: { ...body, url: window.location.origin } }),
  /**
   * Notifies Scouted if a Scouted Local Expert sets a cost over the configured amount
   *
   * @param {Object} body { scout: Object, experience: Object }
   * @returns Success / Fail response
   */
  highCostAlert: (body) => API.post("lambdaApi", "/high-cost", { body }),
  /**
   * Notifies Scouted if a Scouted Local Expert sets a cost of zero
   *
   * @param {Object} body { scout: Object, experience: Object }
   * @returns Success / Fail response
   */
  zeroCostAlert: (body) => API.post("lambdaApi", "/zero-cost", { body: { ...body, url: window.location.origin } }),
  /**
   * Notifies a Scouted Local Expert if a message is recieved by a Scouted
   *
   * @param {Object} body {
   *   userEmail: String,
   *   scoutEmail: String
   *   sent: String,
   *   name: String
   * }
   * @returns Success / Fail response
   */
  newMessage: (body) => API.post("lambdaApi", "/new-message", { body: { ...body, url: window.location.origin } }),
  /**
   * Send notification to info@scouted on new user registration
   *
   * @param {Object} body
   * @returns Success / Fail
   */
  newUserRegistration: (body) =>
    API.post("lambdaApi", "/new-registration", { body: { ...body, url: window.location.origin } }),
  /**
   * Send notification to the business on registration
   *
   * @param {Object} body
   * @returns Success / Fail
   */
  newBusinessRegistration: (body) =>
    API.post("lambdaApi", "/new-business", { body: { ...body, url: window.location.origin } }),
  /**
   * Send notification to the business on registration
   *
   * @param {Object} body
   * @returns Success / Fail
   */
  newUser: (body) => API.post("lambdaApi", "/new-user", { body: { ...body, url: window.location.origin } }),
  /**
   * Send notification to info@scouted on new user sle interest
   *
   * @param {Object} body
   * @returns Success / Fail
   */
  scoutInterest: (body) => API.post("lambdaApi", "/scout-interest", { body: { ...body, url: window.location.origin } }),
  newExperienceNotification: (body) =>
    API.post("lambdaApi", "/new-experience-notification", { body: { ...body, url: window.location.origin } }),
};

/**
 * Functions used to communicate with Stripe Connect api
 */
export const connectService = {
  /**
   * Initial creation of a Connect account for Scouted Local Expert
   *
   * @param {Object} body {
   *   address: {
   *     aptType: String,
   *     aptNumber: String,
   *     address: String,
   *     country: String,
   *     state: String,
   *     city: String,
   *     zipcode: String,
   *   },
   *   dob: {
   *     day: Int,
   *     month: Int,
   *     year: Int,
   *   },
   *   email: String,
   *   firstName: String,
   *   lastName: String,
   * }
   * @returns Connect Id
   */
  createConnectAccount: (body) => API.post("lambdaApi", "/connect/create-account", { body }),
  /**
   * Retrieve Connect account details for Scouted Local Expert
   *
   * @param {Object} body {
   *   id: String
   * }
   * @returns Scouted Local Expert Connect account details
   */
  getConnectAccount: (body) => API.post("lambdaApi", "/connect/account", { body }),
  /**
   * Generate a Connect account login link
   *
   * @param {Object} body {
   *   id: String,
   *   redirect: String,
   *   refreshUrl: String,
   *   returnUrl: String,
   * }
   * @returns Connect Login Url
   */
  connectLogin: (body) => API.post("lambdaApi", "/connect/login", { body }),
  /**
   * Generate a Connect account onboarding link
   *
   * @param {Object} body {
   *   id: String,
   *   refreshUrl: String,
   *   returnUrl: String,
   * }
   * @returns Connect Onboarding Url
   */
  connectOnboarding: (body) => API.post("lambdaApi", "/connect/onboarding", { body }),
};

/**
 * Functions used to communicate with Stripe api
 */
export const stripeService = {
  /**
   * Create a new customer account with stripe
   *
   * @param {Object} body {
   *   email: String,
   *   phone: String,
   *   address: {
   *     city: String,
   *     country: String,
   *     line1: String,
   *     postal_code: String,
   *     state: String,
   *   },
   *   name: String
   * }
   * @returns Customer details Object
   */
  createCustomer: (body) => API.post("lambdaApi", "/stripe/create-customer", { body }),
  /**
   * Upadate customer account with stripe
   *
   * @param {Object} body {
   *   email: String,
   *   phone: String,
   *   address: {
   *     city: String,
   *     country: String,
   *     line1: String,
   *     postal_code: String,
   *     state: String,
   *   },
   *   name: String
   * }
   * @returns Customer details Object
   */
  updateCustomer: (body) => API.post("lambdaApi", "/stripe/update-customer", { body }),
  /**
   * Create a new product in Stripe
   *
   * @param {Object} body {
   *   title: String,
   *   description: String,
   *   images: Array,
   * }
   * @returns Product details Object
   */
  createProduct: (body) => API.post("lambdaApi", "/stripe/create-product", { body }),
  /**
   *
   * @param {Object} body {
   *  id: String,
   *  price: String,
   *  taxCode: String,
   *  scoutedllc: Boolean,
   *  userId: String,
   *  zip: String,
   *  state: String,
   *  street: String,
   *  city: String,
   *  stripeId: String,
   *  success: String,
   *  cancel: String,
   *  quantity: String,
   *  mode: String,
   *  connectId: String,
   *  applicationFee:Int
   * }
   * @returns Stripe session object
   */
  createSession: (body) => API.post("lambdaApi", "/stripe/create-session", { body }),
  /**
   * Create a a refund from stripe back to Scouted if within the configured timeframe
   *
   * @param {Object} body {
   *   booking: Object
   * }
   * @returns Refund details Object
   */
  createRefund: (body) => API.post("lambdaApi", "/stripe/create-refund", { body }),
  /**
   * Retrieve payment details
   *
   * @param {Object} body { pi: String }
   * @returns Refund status
   */
  getPaymentStatus: (body) => API.post("lambdaApi", "/stripe/payment-status", { body }),
  /**
   * Retrieve checkout details
   *
   * @param {Object} body { cs: String }
   * @returns Refund status
   */
  getCheckoutDetails: (body) => API.post("lambdaApi", "/stripe/checkout-details", { body }),
  /**
   * Retrieve checkout details
   *
   * @param {Object} body { cs: String }
   * @returns Refund status
   */
  getSubDetails: (body) => API.post("lambdaApi", "/stripe/sub-details", { body }),
  /**
   * Create a refund request email for admin approval
   *
   * @param {Object} body {
   *  scoutedName: String,
   *  scoutedEmail: String,
   *  experienceName: String,
   *  scoutName:String,
   *  dateBooked:String,
   * }
   * @returns Success / Failure response
   */
  requestRefund: (body) => API.post("lambdaApi", "/request-refund", { body }),
  /**
   * Create a refund email for notifying bookee of a scout initiated cancelation
   *
   * @returns Success / Failure response
   */
  scoutCancellation: (body) => API.post("lambdaApi", "/scout-cancellation", { body }),

  /**
   * Retrieve scouted account details
   *
   * @returns Balance reports
   */
  getStripeReport: (body) => API.post("lambdaApi", "/stripe/reports", { body }),
  /**
   * Create a new Coupon / Promocode in stripe
   *
   * @param {Object} body
   * @returns stripe coupon
   */
  createCoupon: (body) => API.post("lambdaApi", "/create-coupon", { body }),
  /**
   *
   * @param {Object} body
   * @returns updated stripe coupon
   */
  deleteCoupon: (body) => API.post("lambdaApi", "/delete-coupon", { body }),
  getCoupon: (body) => API.post("lambdaApi", "/get-coupon", { body }),
  createTransfer: (body) => API.post("lambdaApi", "/stripe/create-transfer", { body }),
};

/**
 * Functions used to communicate with aws cognito api
 */
export const authService = {
  /**
   *
   * @param {*} data
   * @returns
   */
  signUp: (data) =>
    Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email,
      },
    }),
  resendConfirmationCode: (data) => Auth.resendSignUp(data.email),
  confirmSignUp: (data) => Auth.confirmSignUp(data.email, data.code),
  signIn: (data) => Auth.signIn(data.email, data.password),
  completeNewPassword: (data) =>
    Auth.completeNewPassword(data.user, data.password, {
      email: data.email,
    }),
  isAuthenticated: () => Auth.currentAuthenticatedUser(),
  changePassword: (data) => Auth.changePassword(data.user, data.oldPass, data.newPass),
  signOut: () => Auth.signOut({ global: true }),
  forgotPassword: (data) => Auth.forgotPassword(data.email),
  forgotPasswordSubmit: (data) => Auth.forgotPasswordSubmit(data.email, data.code, data.password),
};

/**
 * Functions used to communicate with location api
 */
export const locationService = {
  /**
   * Request users current location
   *
   * @returns Location object
   */
  getCurrentLocation: () => {
    return new Promise((resolve, reject) => {
      return navigator.geolocation.getCurrentPosition(
        (location) => resolve(location),
        (error) => reject(error),
        { enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 }
      );
    });
  },
};
