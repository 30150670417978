import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

class CategoryCard extends React.Component {
  render() {
    const { name, img, description, altText, pathName } = this.props;
    return (
      <Link
        to={{
          pathname: `${pathName}`,
          params: {
            filters: { category: name },
          },
        }}
      >
        <Card
          className="experience-image"
          style={{ margin: "1rem 0.5rem 0 0.25rem !important" }}
        >
          <Card.Img variant="top" className="img-fit-category" src={img} alt={altText} />
          <Card.ImgOverlay>
            <Card.Footer className="tag-bottom experience-tag2">
              <p className="category-header-small p-0">{name}</p>
              <p className="category-text-small extra px-2">{description}</p>
            </Card.Footer>
          </Card.ImgOverlay>
        </Card>
      </Link>
    );
  }
}

export default CategoryCard;
