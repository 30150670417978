/**
 * Constant variables for easy refence app wide
 */
import moment from "moment";

export const BUSINESS_REGISTRATION_SUCCESS_URL = `${window.location.origin}/business/purchase-confirmation`;
export const BUSINESS_REGISTRATION_CANCELLED_URL = `${window.location.origin}/business/subscription-payment-cancelled`;
/**
 * Redirect url for Sterling proccess fails / abandoned
 */
export const REGISTRATION_CANCELLED_URL = `${window.location.origin}/be-a-scout/subscription-payment-cancelled`;
/**
 * Redirect url for Sterling process succeeding
 */
export const REGISTRATION_SUCCESS_URL = `${window.location.origin}/be-a-scout/purchase-confirmation`;
/**
 * Experience lead times (How long someone is able to book an experience before start time)
 */
export const REFUND_POLICY = [
  {
    text: "1 Business Day",
    mode: "Relaxed",
    min: 0,
  },
  {
    text: "3 Business Days",
    mode: "Moderate",
    min: 3,
  },
  {
    text: "7+ Business Days",
    mode: "Strict",
    min: 7,
  },
];
/**
 * Experience lead times (How long someone is able to book an experience before start time)
 */
export const EXPERIENCE_LEAD = [
  {
    text: "1-3 Business Days",
    mode: "Relaxed",
    min: 1,
    max: 3,
  },
  {
    text: "3-7 Business Days",
    mode: "Moderate",
    min: 3,
    max: 7,
  },
  {
    text: "7+ Business Days",
    mode: "Strict",
    min: 7,
    max: null,
  },
];
/**
 * List of states long and short names
 */
export const STATES = [
  { long: "Alabama", short: "AL" },
  { long: "Alaska", short: "AK" },
  { long: "Arizona", short: "AZ" },
  { long: "Arkansas", short: "AR" },
  { long: "California", short: "CA" },
  { long: "Colorado", short: "CO" },
  { long: "Connecticut", short: "CT" },
  { long: "Delaware", short: "DE" },
  { long: "Florida", short: "FL" },
  { long: "Georgia", short: "GA" },
  { long: "Hawaii", short: "HI" },
  { long: "Idaho", short: "ID" },
  { long: "Illinois", short: "IL" },
  { long: "Indiana", short: "IN" },
  { long: "Iowa", short: "IA" },
  { long: "Kansas", short: "KS" },
  { long: "Kentucky", short: "KY" },
  { long: "Louisiana", short: "LA" },
  { long: "Maine", short: "ME" },
  { long: "Maryland", short: "MD" },
  { long: "Massachusetts", short: "MA" },
  { long: "Michigan", short: "MI" },
  { long: "Minnesota", short: "MN" },
  { long: "Mississippi", short: "MS" },
  { long: "Missouri", short: "MO" },
  { long: "Montana", short: "MT" },
  { long: "Nebraska", short: "NE" },
  { long: "Nevada", short: "NV" },
  { long: "New Hampshire", short: "NH" },
  { long: "New Jersey", short: "NJ" },
  { long: "New Mexico", short: "NM" },
  { long: "New York", short: "NY" },
  { long: "North Carolina", short: "NC" },
  { long: "North Dakota", short: "ND" },
  { long: "Ohio", short: "OH" },
  { long: "Oklahoma", short: "OK" },
  { long: "Oregon", short: "OR" },
  { long: "Pennsylvania", short: "PA" },
  { long: "Rhode Island", short: "RI" },
  { long: "South Carolina", short: "SC" },
  { long: "South Dakota", short: "SD" },
  { long: "Tennessee", short: "TN" },
  { long: "Texas", short: "TX" },
  { long: "Utah", short: "UT" },
  { long: "Vermont", short: "VT" },
  { long: "Virginia", short: "VA" },
  { long: "Washington", short: "WA" },
  { long: "West Virginia", short: "WV" },
  { long: "Wisconsin", short: "WI" },
  { long: "Wyoming", short: "WY" },
  // { long: "Alberta", short: "AB" },
  // { long: "British Columbia", short: "BC" },
  // { long: "Manitoba", short: "MB" },
  // { long: "New Brunswick", short: "NB" },
  // { long: "Newfoundland and Labrador", short: "NL" },
  // { long: "Nunavut", short: "NU" },
  // { long: "Northwest Territories", short: "NT" },
  // { long: "Nova Scotia", short: "NS" },
  // { long: "Ontario", short: "ON" },
  // { long: "Prince Edward Island", short: "PE" },
  // { long: "Quebec", short: "QC" },
  // { long: "Saskatchewan", short: "SK" },
];
/**
 * Redirect url for Stripe payment failure / cancellation
 */
export const PAYMENT_CANCELLED_URL = `${window.location.origin}/payment-cancelled`;
/**
 * Redirect url for Stripe payment success
 */
export const PAYMENT_SUCCESS_URL = `${window.location.origin}/payment-success`;
/**
 * Redirect url for Stripe connect account completion
 */
export const CONNECT_RETURN_URL = `${window.location.origin}/registration-progress`;
/**
 * Redirect url for Stripe if reauthentication is d
 */
export const CONNECT_REFRESH_URL = `${window.location.origin}/reauth`;
/**
 * Check and set scouted local expert regitstraion product id
 */
export const REGISTRATION_ID =
  process.env.NODE_ENV === "production" && window.location.origin === "https://www.scouted.com"
    ? `prod_JkTfEnMRikMNFR`
    : `prod_LEFbyeJDiBIHUU`;
/**
 * Default map center if no coordinates found
 */
export const DEFAULT_GPS = {
  latitude: 40,
  longitude: -96,
};
/**
 * Regular expression to check if an email address is in the correct format
 */
export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
);
/**
 * Regular expression to check if a phone number is in the correct format
 */
export const PHONE_REGEX = new RegExp(/^\d{3}-\d{3}-\d{4}$/);
/**
 * Regular expression to check if a password is in the correct format
 */
export const PASSWORD_REGEX = new RegExp(/^(?=[A-Za-z0-9!@#$%^&*()\-_+={}[\]|\\:";'<>,./?~`]).{8,30}$/);
/**
 * Regular expression to check if a price is in the correct format
 */
export const PRICE_REGEX = new RegExp(/^[1-9]{1}[0-9]*\.{1}[0-9]{2}$/);
/**
 * Regular expression to check if a username is in the correct format
 */
export const USERNAME_REGEX = new RegExp(/^[a-z0-9]{1}[a-z0-9-]{2,39}$/);
/**
 * Regular expression to check if a zip code is in the correct format
 */
export const ZIP_REGEX = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
/**
 * Tax options for creating an experience
 */
export const TAXATION_OPTIONS = [
  { value: "19000", displayName: "19000-General Services" },
  { value: "19003", displayName: "19003-Admission Services" },
  { value: "19004", displayName: "19004-Training Services" },
  { value: "19005", displayName: "19005-Professional Services" },
  { value: "19007", displayName: "19007-Repair Services" },
  { value: "31000", displayName: "31000-Digital Goods" },
  { value: "0", displayName: "0-Other" },
];
/**
 * Default time before messaging service is enabled on bookings
 */
export const MESSAGE_TIMING = 0;
export const THUMBNAIL_URL_SETTINGS = "https://res.cloudinary.com/dam4j2o9t/image/upload/w_150,f_auto,q_auto/";
export const REGULAR_IMAGE_SETTINGS = "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/";
export const CATEGORY_IMAGE_SETTINGS = "https://res.cloudinary.com/dam4j2o9t/image/upload/c_fill,w_700,f_auto,q_20/";
export const FEATURED_URL_SETTINGS = "https://res.cloudinary.com/dam4j2o9t/image/upload/c_fill,w_700,f_auto,q_10/";
export const SEARCH_CARD_URL_SETTINGS = "https://res.cloudinary.com/dam4j2o9t/image/upload/c_fill,w_700,f_auto,q_10/";
/**
 * Default images
 */
export const LOGO_NUM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1663923364/assets/scouted-logo/logo_clls83.png";
export const LOGO_NO_NUM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1663923343/assets/scouted-logo/edit-spacer_vvezm7.png";
export const EDIT_SPACER =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1663923343/assets/scouted-logo/edit-spacer_vvezm7.png";
export const NOT_FOUND =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1619103153/assets/not-found_dwhhxl.jpg";
export const LOGO_TEXT =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1617905620/assets/scouted-logo/logo-text_whtbg_tn2nnk.png";
export const STRIPE_LOGO =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/w_300/v1664209302/assets/payment-images/stripe-logo_gbeb3p.png";
export const LOGO =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_autov1615892824/assets/scouted-logo/logo_nrqclh.png";
export const INVERTED_LOGO =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615892824/assets/scouted-logo/inverted-logo_wrcq28.png";
export const LANDING_HERO =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/c_fill,w_auto,f_auto,q_auto/v1615895256/assets/landing-hero_p0ktrq.jpg";
export const ABOUT_TOP =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615896296/assets/about-scouted/top_ror9cu.jpg";
export const CONTACT_BOTTOM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615897275/assets/contact-scouted/contact-scouted-bottom_vzaphz.jpg";
export const SUPPORT_TOP =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615897362/assets/contact-scouted/support-top_nhno5v.jpg";
export const SUPPORT_BOTTOM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615897368/assets/contact-scouted/support-bottom_obhm4y.jpg";
export const PAYMENT_SUCCESS =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615897679/assets/payment-images/payment-success_nvjmo2.jpg";
export const PAYMENT_FAILED =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615897679/assets/payment-images/payment-failed_krioho.jpg";
export const HIW_TOP =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615898660/assets/how-it-works/top_bnfszs.jpg";
export const HIW_MID_1 =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615898660/assets/how-it-works/mid_kbmo5r.jpg";
export const HIW_MID_2 =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615898660/assets/how-it-works/mid2_eokzjg.jpg";
export const HIW_BOTTOM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615898660/assets/how-it-works/bottom_z9j7a2.jpg";
export const WTS_TOP =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615899028/assets/why-trust-scouted/top_hkxtnm.jpg";
export const WTS_BOTTOM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615899028/assets/why-trust-scouted/bottom_czkmby.jpg";
export const WAH_TOP =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615899179/assets/we-are-hiring/top_drfsi0.jpg";
export const WAH_BOTTOM =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615899179/assets/we-are-hiring/bottom_vyhtll.jpg";
export const BACKGROUND_CHECK =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615900130/assets/payment-images/booking-success_kl21hx.jpg";
export const CREATE_TOP =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615900540/assets/create-experience/create-experience-top_eyddt8.jpg";
export const CREATE_PLACEHOLDER =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901172/assets/create-experience/add-image_gvfwya.png";
export const MESSAGES =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901347/assets/messages_kesdek.jpg";
export const NOTIFICATIONS =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901368/assets/notifications_wps9cf.jpg";
export const TWITTER_SEARCH =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901705/assets/twitter/search_n9ugdh.jpg";
export const TWITTER_CREATED =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901705/assets/twitter/created-experiences_auwg1b.jpg";
export const TWITTER_DETAILS =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901705/assets/twitter/experience-details_zzupu5.jpg";
export const TWITTER_LANDING =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901705/assets/twitter/landing-page_asnsia.jpg";
export const TWITTER_PROFILE =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901705/assets/twitter/profile_g0xixe.jpg";
export const TWITTER_MAIN =
  "https://res.cloudinary.com/dam4j2o9t/image/upload/f_auto,q_auto/v1615901705/assets/twitter/main_uslukw.jpg";
/**
 * Default list of categories with details
 */
export const CATEGORIES = [
  {
    pathName: "/search/local-business",
    slug: "local-business",
    name: "Local Business",
    img: `${CATEGORY_IMAGE_SETTINGS}v1632135218/assets/categories/rentals_zlrivx.jpg`,
    description:
      "Want to try something new? Looking for local businesses with great experiences while you’re away? View our curated list here!",
    count: 0,
    altText: "Local Business in my area",
  },
  {
    pathName: "/search/lodging",
    slug: "lodging",
    name: "Lodging",
    img: `${CATEGORY_IMAGE_SETTINGS}v1677147494/assets/categories/lodging_jcyklo.jpg`,
    description:
      "Looking for vacation rentals, AirBnBs, lodging, resorts, hotels and more? We've chosen from the best, click here and see!",
    count: 0,
    altText: "Lodging",
  },
  {
    pathName: "/search/outfitters-and-guides",
    slug: "outfitters-and-guides",
    name: "Outfitters & Guides",
    img: `${CATEGORY_IMAGE_SETTINGS}v1675445605/assets/categories/guides_j86wl3.png`,
    description: "Have gear to rent or are you a registered guide? This is the category for you!",
    count: 0,
    altText: "Outfitters and Guides",
  },
  {
    pathName: "/search/outdoor",
    slug: "outdoor",
    name: "Outdoor",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/outdoor_dwbh69.jpg`,
    description:
      "Whether you’re eager to get on the trail or want a relaxing day on a lake, there’s a perfect outdoor adventure waiting for you!",
    count: 0,
    altText: "Find outdoor adventure",
  },
  {
    pathName: "/search/family",
    slug: "family",
    name: "Family",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/family_mcrvvp.jpg`,
    description:
      "Need a little something for everyone? No problem! Check out these family friendly activities that are sure to make everyone happy!",
    count: 0,
    altText: "Family Friendly Activities",
  },
  {
    pathName: "/search/gps-experiences",
    slug: "gps-experiences",
    name: "GPS Experiences",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/downloadable-content_be2bnx.jpg`,
    description:
      "Want to explore solo but don’t know the area? Grab a GPS experience created by a Scout and explore on your own terms - all you need is your phone.",
    count: 0,
    altText: "Download GPS Adventures",
  },
  {
    pathName: "/search/expert-led-experiences",
    slug: "expert-led-experiences",
    name: "Expert Led Experiences",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/tours_yiskrc.jpg`,
    description:
      "Want to meet up with a local who can take you out to show you everything they know? Whether its classes, tours, trails or skill sharing, if you’re looking for in-person experiences this is the category for you!",
    count: 0,
    altText: "Expert Guided Adventures",
  },
  {
    pathName: "/search/culture",
    slug: "culture",
    name: "Culture",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/local-cultures_erjkzl.jpg`,
    description:
      "If you love learning about the places you travel to, this is the category for you! Find all the best cultural hotspots, events and learn about the city you’re in! ",
    count: 0,
    altText: "Cultural Things To Do",
  },
  {
    pathName: "/search/21+",
    slug: "21+",
    name: "21+",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/21_z4n8ze.jpg`,
    description: "From wine tasting to team building, these activities are exclusively for adults.",
    count: 0,
    altText: "Over 21 Events",
  },
  {
    pathName: "/search/accessibility",
    slug: "accessibility",
    name: "Accessibility",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/accessibility_fppcot.jpg`,
    description: "With Scouted, you can rest assured that we have activities for every mobility level!",
    count: 0,
    altText: "Wheelchair Accessible Activities",
  },
  {
    pathName: "/search/classes-and-workshops",
    slug: "classes-and-workshops",
    name: "Classes & Workshops",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/classes-and-workshops_gq1uzk.jpg`,
    description:
      "Want to try something new? Looking for a way to keep on top of your fitness while you’re away? We've listed only the best!",
    count: 0,
    altText: "Classes and Workshops",
  },
  {
    pathName: "/search/pet-friendly",
    slug: "pet-friendly",
    name: "Pet Friendly",
    img: `${CATEGORY_IMAGE_SETTINGS}v1615895460/assets/categories/pet-friendly_b63eqc.jpg`,
    description:
      "Have an animal companion? Looking for great experiences while out with your best friend? Bring your furry friend along to these listings!",
    count: 0,
    altText: "Pet Friendly Activities",
  },
];
/**
 * Default user object
 */
export const EMPTY_USER = {
  userId: "",
  username: "",
  isAdmin: false,
  isScout: false,
  isBusiness: false,
  businessUrl: "",
  businessName: "",
  firstName: "",
  lastName: "",
  gender: "",
  dob: "",
  bio: "",
  avatar: "",
  email: "",
  phone: "",
  address: {
    aptType: "",
    aptNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
  },
  stripeId: "",
  isGeneralNewsletterSubscribed: false,
  isNotificationEmailSubscribed: false,
  isSalesPromotionSubscribed: false,
  isSiteNotificationSubscribed: true,
  followList: [],
  connectId: "",
  isInterested: false,
  completedProfile: false,
  createdExperience: false,
  sterlingSubmition: false,
  registrationFeePaid: false,
  connectAccountCreation: false,
  connectAccountVerification: false,
  authorization: [],
  screeningId: "",
  sterlingClientId: "",
  screeningResult: "",
  screeningECT: "",
  createdOn: moment().toISOString(),
  screeningEmailSent: false,
  cancelled: false,
  deactivatedByAdmin: false,
  lastLogin: moment().toISOString(),
  favoriteExperiences: [],
  isReferer: false,
  affiliateId: "",
  isFinanceAdmin: false,
  isCsAdmin: false,
};
/**
 * Default configuration object
 */
export const EMPTY_CONFIG = {
  isBackgroundCheckEnabled: true,
  isBackgroundCheckPaymentEnabled: true,
  amount: "",
  platformFee: [{ updatedOn: "", latestPercent: 0 }],
  highCostAlert: "",
};
/**
 * Default massaging object
 */
export const EMPTY_MESSAGE = {
  toId: "",
  to: {},
  fromId: "",
  from: {},
  experience: {},
  messages: [{ message: "", id: "" }],
  isMuted: false,
  mutedBy: null,
  mutedDate: null,
  isEdited: false,
  editedBy: null,
  editedDate: null,
  isFlagged: false,
  flaggedBy: null,
  flaggedDate: null,
  createdAt: null,
  updateAt: null,
  seenByScout: false,
  seenByScouted: false,
};
/**
 * Defaul experience object
 */
export const EMPTY_EXPERIENCE = {
  accessibility: "Fully Accessible",
  availabilityNotificationSent: false,
  active: false,
  activityLevel: "Light",
  always: false,
  cancellationPolicy: "",
  categories: [],
  dates: [],
  description: "",
  experienceLevel: "Beginner",
  gpsData: [],
  highlight: "",
  images: [],
  itemsProvided: [],
  itemsToBring: [],
  location: "",
  price: "",
  rebookPolicy: "",
  scoutId: "",
  title: "",
  type: "",
  kebabTitle: "",
  maxParticipants: 1,
  isHosted: "Hosted",
  minAge: "Any",
  sku: "",
  stripeProdId: "",
  taxCategory: "19000",
  isFeatured: false,
  createdOn: moment().toISOString(),
  updatedOn: moment().toISOString(),
  dateRequest: [],
  deactivatedByAdmin: false,
  leadTime: "Moderate",
  refundPolicy: "Moderate",
};
/**
 * Default review object
 */
export const EMPTY_REVIEW = {
  comment: "",
  scoutResponse: "",
  rating: 3,
  image: "",
  isReply: false,
  isMuted: false,
  mutedBy: "",
  mutedDate: "",
  isEdited: false,
  editedBy: "",
  editedDate: "",
  isFlagged: false,
  flaggedBy: "",
  flaggedDate: "",
  flaggedNote: "",
  createdOn: "",
  updatedOn: "",
};
/**
 * Default booking object
 */
export const EMPTY_BOOKING = {
  scouted: null,
  experience: null,
  scoutedId: "",
  experienceId: "",
  participants: 0,
  bookingDate: null,
  sessionId: "",
  amount: 0,
  paymentIntent: "",
  refundId: "",
  refundPolicy: "",
  messaging: null,
  review: null,
  isHold: false,
  createdOn: moment().toISOString(),
  updatedOn: moment().toISOString(),
  affiliateId: "",
};
/**
 * Default view tracking object
 */
export const EMPTY_VIEW = {
  experienceId: "",
  count: 0,
  viewers: [{ userId: "", viewedOn: moment().toISOString() }],
};
/**
 * Default transaction object
 */
export const EMPTY_TRANSACTION = {
  processedOn: moment().toISOString(),
  type: null,
  amount: null,
  applicationFee: null,
  location: null,
  scoutId: null,
  scoutedId: null,
  experienceId: null,
  categories: null,
  paymentIntent: null,
  refunded: false,
  refundId: null,
  affiliateId: null,
  taxCollected: null,
  affiliateAmount: null,
};

export const EMPTY_AFFILIATE = {
  userId: null,
  code: null,
  newUsers: null,
  count: 0,
  createdOn: moment().toISOString(),
};
/**
 * Default coupon object
 */
export const EMPTY_COUPON = {
  amountOff: null,
  currency: "usd",
  duration: null,
  durationInMonths: null,
  maxRedemptions: null,
  name: null,
  percentOff: null,
  redeemBy: null,
  timesRedeemed: null,
  valid: true,
};
/**
 * Default scouted local expert application fee
 */
export const APPLICATION_FEE = 0.25;
/**
 * Scouted local expert registration list
 */
export const REGISTRATION_STAGES = [
  "Complete Bio and DOB",
  "Review & Accept TOS",
  "Background Check & Registration Fee",
  "Background Check",
  "Registration Fee",
  "Activate Status",
  "Create Username",
  "Create Connect Account",
  "Continue Connect account creation",
  "Submit Identity Verification",
  "Additional Connect Details Needed",
  "Let's Create!",
  "Activate an Experience",
];
/**
 * Bad words list for not allowed usernames
 */
export const BAD_WORDS = [
  "4r5e",
  "5h1t",
  "5hit",
  "a55",
  "anal",
  "anus",
  "ar5e",
  "arrse",
  "arse",
  "ass",
  "ass-fucker",
  "asses",
  "assfucker",
  "assfukka",
  "asshole",
  "assholes",
  "asswhole",
  "a_s_s",
  "b!tch",
  "b00bs",
  "b17ch",
  "b1tch",
  "ballbag",
  "balls",
  "ballsack",
  "bastard",
  "beastial",
  "beastiality",
  "bellend",
  "bestial",
  "bestiality",
  "bi+ch",
  "biatch",
  "bitch",
  "bitcher",
  "bitchers",
  "bitches",
  "bitchin",
  "bitching",
  "bloody",
  "blow job",
  "blowjob",
  "blowjobs",
  "boiolas",
  "bollock",
  "bollok",
  "boner",
  "boob",
  "boobs",
  "booobs",
  "boooobs",
  "booooobs",
  "booooooobs",
  "breasts",
  "buceta",
  "bugger",
  "bum",
  "bunny fucker",
  "butt",
  "butthole",
  "buttmuch",
  "buttplug",
  "c0ck",
  "c0cksucker",
  "carpet muncher",
  "cawk",
  "chink",
  "cipa",
  "cl1t",
  "clit",
  "clitoris",
  "clits",
  "cnut",
  "cock",
  "cock-sucker",
  "cockface",
  "cockhead",
  "cockmunch",
  "cockmuncher",
  "cocks",
  "cocksuck ",
  "cocksucked ",
  "cocksucker",
  "cocksucking",
  "cocksucks ",
  "cocksuka",
  "cocksukka",
  "cok",
  "cokmuncher",
  "coksucka",
  "coon",
  "cox",
  "crap",
  "cum",
  "cummer",
  "cumming",
  "cums",
  "cumshot",
  "cunilingus",
  "cunillingus",
  "cunnilingus",
  "cunt",
  "cuntlick ",
  "cuntlicker ",
  "cuntlicking ",
  "cunts",
  "cyalis",
  "cyberfuc",
  "cyberfuck ",
  "cyberfucked ",
  "cyberfucker",
  "cyberfuckers",
  "cyberfucking ",
  "d1ck",
  "damn",
  "dick",
  "dickhead",
  "dildo",
  "dildos",
  "dink",
  "dinks",
  "dirsa",
  "dlck",
  "dogfucker",
  "dog-fucker",
  "doggin",
  "dogging",
  "donkeyribber",
  "doosh",
  "duche",
  "dyke",
  "ejaculate",
  "ejaculated",
  "ejaculates ",
  "ejaculating ",
  "ejaculatings",
  "ejaculation",
  "ejakulate",
  "f u c k",
  "f u c k e r",
  "f4nny",
  "fag",
  "fagging",
  "faggitt",
  "faggot",
  "faggs",
  "fagot",
  "fagots",
  "fags",
  "fanny",
  "fannyflaps",
  "fannyfucker",
  "fanyy",
  "fatass",
  "fcuk",
  "fcuker",
  "fcuking",
  "feck",
  "fecker",
  "felching",
  "fellate",
  "fellatio",
  "fingerfuck ",
  "fingerfucked ",
  "fingerfucker ",
  "fingerfuckers",
  "fingerfucking ",
  "fingerfucks ",
  "fistfuck",
  "fistfucked ",
  "fistfucker ",
  "fistfuckers ",
  "fistfucking ",
  "fistfuckings ",
  "fistfucks ",
  "flange",
  "fook",
  "fooker",
  "fuck",
  "fucka",
  "fucked",
  "fucker",
  "fuckers",
  "fuckhead",
  "fuckheads",
  "fuckin",
  "fucking",
  "fuckings",
  "fuckingshitmotherfucker",
  "fuckme ",
  "fucks",
  "fuckwhit",
  "fuckwit",
  "fudge packer",
  "fudgepacker",
  "fuk",
  "fuker",
  "fukker",
  "fukkin",
  "fuks",
  "fukwhit",
  "fukwit",
  "fux",
  "fux0r",
  "f_u_c_k",
  "gangbang",
  "gangbanged ",
  "gangbangs ",
  "gaylord",
  "gaysex",
  "goatse",
  "God",
  "god-dam",
  "god-damned",
  "goddamn",
  "goddamned",
  "hardcoresex ",
  "hell",
  "heshe",
  "hoar",
  "hoare",
  "hoer",
  "homo",
  "hore",
  "horniest",
  "horny",
  "hotsex",
  "jack-off ",
  "jackoff",
  "jap",
  "jerk-off ",
  "jism",
  "jiz ",
  "jizm ",
  "jizz",
  "kawk",
  "knob",
  "knobead",
  "knobed",
  "knobend",
  "knobhead",
  "knobjocky",
  "knobjokey",
  "kock",
  "kondum",
  "kondums",
  "kum",
  "kummer",
  "kumming",
  "kums",
  "kunilingus",
  "l3i+ch",
  "l3itch",
  "labia",
  "lmfao",
  "lust",
  "lusting",
  "m0f0",
  "m0fo",
  "m45terbate",
  "ma5terb8",
  "ma5terbate",
  "masochist",
  "master-bate",
  "masterb8",
  "masterbat*",
  "masterbat3",
  "masterbate",
  "masterbation",
  "masterbations",
  "masturbate",
  "mo-fo",
  "mof0",
  "mofo",
  "mothafuck",
  "mothafucka",
  "mothafuckas",
  "mothafuckaz",
  "mothafucked ",
  "mothafucker",
  "mothafuckers",
  "mothafuckin",
  "mothafucking ",
  "mothafuckings",
  "mothafucks",
  "mother fucker",
  "motherfuck",
  "motherfucked",
  "motherfucker",
  "motherfuckers",
  "motherfuckin",
  "motherfucking",
  "motherfuckings",
  "motherfuckka",
  "motherfucks",
  "muff",
  "mutha",
  "muthafecker",
  "muthafuckker",
  "muther",
  "mutherfucker",
  "n1gga",
  "n1gger",
  "nazi",
  "nigg3r",
  "nigg4h",
  "nigga",
  "niggah",
  "niggas",
  "niggaz",
  "nigger",
  "niggers ",
  "nob",
  "nob jokey",
  "nobhead",
  "nobjocky",
  "nobjokey",
  "numbnuts",
  "nutsack",
  "orgasim ",
  "orgasims ",
  "orgasm",
  "orgasms ",
  "p0rn",
  "pawn",
  "pecker",
  "penis",
  "penisfucker",
  "phonesex",
  "phuck",
  "phuk",
  "phuked",
  "phuking",
  "phukked",
  "phukking",
  "phuks",
  "phuq",
  "pigfucker",
  "pimpis",
  "piss",
  "pissed",
  "pisser",
  "pissers",
  "pisses ",
  "pissflaps",
  "pissin ",
  "pissing",
  "pissoff ",
  "poop",
  "porn",
  "porno",
  "pornography",
  "pornos",
  "prick",
  "pricks ",
  "pron",
  "pube",
  "pusse",
  "pussi",
  "pussies",
  "pussy",
  "pussys ",
  "rectum",
  "retard",
  "rimjaw",
  "rimming",
  "s hit",
  "s.o.b.",
  "sadist",
  "schlong",
  "screwing",
  "scroat",
  "scrote",
  "scrotum",
  "semen",
  "sex",
  "sh!+",
  "sh!t",
  "sh1t",
  "shag",
  "shagger",
  "shaggin",
  "shagging",
  "shemale",
  "shi+",
  "shit",
  "shitdick",
  "shite",
  "shited",
  "shitey",
  "shitfuck",
  "shitfull",
  "shithead",
  "shiting",
  "shitings",
  "shits",
  "shitted",
  "shitter",
  "shitters ",
  "shitting",
  "shittings",
  "shitty ",
  "skank",
  "slut",
  "sluts",
  "smegma",
  "smut",
  "snatch",
  "son-of-a-bitch",
  "spac",
  "spunk",
  "s_h_i_t",
  "t1tt1e5",
  "t1tties",
  "teets",
  "teez",
  "testical",
  "testicle",
  "tit",
  "titfuck",
  "tits",
  "titt",
  "tittie5",
  "tittiefucker",
  "titties",
  "tittyfuck",
  "tittywank",
  "titwank",
  "tosser",
  "turd",
  "tw4t",
  "twat",
  "twathead",
  "twatty",
  "twunt",
  "twunter",
  "v14gra",
  "v1gra",
  "vagina",
  "viagra",
  "vulva",
  "w00se",
  "wang",
  "wank",
  "wanker",
  "wanky",
  "whoar",
  "whore",
  "willies",
  "willy",
  "xrated",
  "xxx",
  "Sucks",
  "suck",
  "sonofabitch",
  "SOB",
  "jerkoff",
];
