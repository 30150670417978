import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";

class MetaTags extends PureComponent {
  render() {
    const { title, description, image, url } = this.props;
    if (process.env.NODE_ENV === "production" && window.location.origin === "https://www.scouted.com") {
      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={url} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content="Scouted - Life Experiences" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      );
    } else {
      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={url} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content="Scouted - Life Experiences" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      );
    }
  }
}

MetaTags.defaultProps = {
  title: "",
  description: "",
  image: "",
  url: "https://www.scouted.com/",
};

export default MetaTags;
