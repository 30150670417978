// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const FollowStatus = {
  "FOLLOWING": "following",
  "FOLLOWER": "follower"
};

const ExperienceType = {
  "SINGLE": "single",
  "MULTIPLE": "multiple",
  "PATH": "path",
  "MANUAL": "manual"
};

const TransactionType = {
  "REGISTRATION_FEE": "registrationFee",
  "PLATFORM_FEE": "platformFee",
  "CONNECT_PAYOUT": "connectPayout",
  "PURCHASE_AMOUNT": "purchaseAmount",
  "REFUND_AMOUNT": "refundAmount",
  "BUSINESS_SUB": "businessSub"
};

const DurationType = {
  "ONCE": "once",
  "REPEATING": "repeating",
  "FOREVER": "forever"
};

const { User, Experience, Booking, Configuration, Views, Affiliates, Transactions, Coupon, TafMessages, Transfer, NewUser, Viewer, Review, Message, RefundRequest, DateRequest, FollowList, Address, Authorization, Availability, Original, Coords, GpsData, MessageDetails, PlatformDetails } = initSchema(schema);

export {
  User,
  Experience,
  Booking,
  Configuration,
  Views,
  Affiliates,
  Transactions,
  Coupon,
  TafMessages,
  Transfer,
  FollowStatus,
  ExperienceType,
  TransactionType,
  DurationType,
  NewUser,
  Viewer,
  Review,
  Message,
  RefundRequest,
  DateRequest,
  FollowList,
  Address,
  Authorization,
  Availability,
  Original,
  Coords,
  GpsData,
  MessageDetails,
  PlatformDetails
};