import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { authService } from "src/api/apiActions";
import { userActions } from "src/db/dbActions";
import { LOGO_TEXT, STRIPE_LOGO } from "src/constants";
import { CURRENT_VERSION } from "src/cred";
import "src/styles/Footer.css";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = { user: null };
  }
  componentDidMount() {
    authService
      .isAuthenticated()
      .then((res) => {
        userActions.getCurrentUser({ email: res.attributes.email }).then((currUser) => {
          this.setState({ user: currUser[0] });
        });
      })
      .catch((e) => e);
  }

  render() {
    const { user } = this.state;
    return (user && user.isAdmin && window.location.pathname.includes("admin")) ||
      window.location.pathname.includes("edit") ? (
      <Container className="my-5">
        <Row className="text-center">
          <Col>
            &copy; Copyright <strong>Scouted.&nbsp;</strong>
            All Rights Reserved
          </Col>
        </Row>
      </Container>
    ) : (
      <footer
        id="footer"
        className={user ? "page-footer font-small p-2 pt-5 footer-menu-margin" : "page-footer font-small p-2 pt-5"}
      >
        <Container fluid className="text-center text-sm-start text-md-start">
          <Row className="text-center text-sm-start text-md-start mt-5 pb-3">
            <Col xs={12} sm={3} md={6} lg={6} xl={2} className="pb-5 text-center">
              <Image
                alt="Scouted LLC Logo"
                className="img-fluid logo my-auto pe-sm-5 pe-md-0 pb-lg-3"
                src={LOGO_TEXT}
              />
            </Col>
            <Col xs={12} sm={9} md={6} lg={6} xl={4} className="mx-auto pe-lg-5 pe-md-4 ps-sm-5 pt-md-0 pt-lg-0">
              <h6 className="text-uppercase mb-4 font-weight-bold">Scouted LLC</h6>
              <p>
                <strong>Scouted:</strong> Experience a more authentic way of traveling. We've built upon a combined 50
                years in the tourism industry.
              </p>
              <p>
                For intrepid travelers and local experts alike, Scouted is your online platform to find and share
                authentic experiences and unique locations from across the country!
              </p>
            </Col>

            <Col xs={12} sm={4} md={4} lg={4} xl={2} className=" mx-auto">
              <h6 className="text-uppercase mb-4 font-weight-bold">Legal</h6>
              <p>
                <Link to={"/cancellation-policy"}>Cancellation Policy</Link>
              </p>
              <p>
                <Link to={"/privacy"}>Privacy Policy</Link>
              </p>
              <p>
                <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>
              </p>
            </Col>

            <Col xs={12} sm={4} md={4} lg={4} xl={2} className="mx-auto text-sm-center text-md-center text-lg-center">
              <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
              <p>
                <Link to={"/contact-scouted"}>Contact Us</Link>
              </p>
              <p>
                <Link to={"/customer-service"}>Customer Service</Link>
              </p>
              <p>
                <Link to={"/support"}>Tech Support</Link>
              </p>
            </Col>

            <Col xs={12} sm={4} md={4} lg={4} xl={2} className="ms-auto text-sm-end text-md-end text-lg-end pe-md-5">
              <h6 className="text-uppercase mb-4 font-weight-bold">Links</h6>
              <p>
                <a href="https://blog.scouted.com/" target="_blank" rel="noopener noreferrer">
                  Blog
                </a>
              </p>
              <p>
                <Link to={"/we-are-hiring"}>We are Hiring!</Link>
              </p>
              <p>
                <Link to={"/about/scouted"}>About Scouted</Link>
              </p>
            </Col>
          </Row>
          <hr />
          <Row className="d-flex mb-2">
            <Col xs={12} sm={8} md={8} lg={9}>
              <p className="text-center text-sm-start">
                © 2019 - {new Date().getFullYear()} Copyright{" "}
                <a href="/">
                  <strong> Scouted LLC</strong>
                </a>
              </p>
              <p style={{ fontSize: "10px" }}>Version - {CURRENT_VERSION}</p>
              <Image
                alt="Scouted LLC Logo"
                className="img-fluid logo my-auto pe-sm-5 pe-md-0 pb-lg-3 pt-md-3"
                src={STRIPE_LOGO}
              />
            </Col>
            <Col xs={12} sm={4} md={4} lg={3} className="d-flex justify-content-end">
              <ListGroup horizontal as="ul" className="py-5" id="socialmedia">
                <ListGroup.Item as="li" className="p-0">
                  <a href="https://www.facebook.com/scoutedapp/" target="_blank" rel="noopener noreferrer">
                    <Button className="btn-floating btn-fb btn-sm rgba-white-slight">
                      <FontAwesomeIcon className="btn-fb" icon={["fab", "facebook-f"]} />
                    </Button>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="p-0">
                  <a href="https://twitter.com/scoutedexp" target="_blank" rel="noopener noreferrer">
                    <Button className="btn-floating btn-tw btn-sm rgba-white-slight">
                      <FontAwesomeIcon className="btn-tw" icon={["fab", "twitter"]} />
                    </Button>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 ">
                  <a href="https://www.instagram.com/scouted.app/" target="_blank" rel="noopener noreferrer">
                    <Button className="btn-floating btn-in btn-sm rgba-white-slight">
                      <FontAwesomeIcon className="btn-in" icon={["fab", "instagram"]} />
                    </Button>
                  </a>
                </ListGroup.Item>
                <ListGroup.Item className="p-0 ">
                  <a href="https://www.linkedin.com/company/scouted-llc/" target="_blank" rel="noopener noreferrer">
                    <Button className="btn-floating btn-lk btn-sm rgba-white-slight">
                      <FontAwesomeIcon className="btn-lk" icon={["fab", "linkedin-in"]} />
                    </Button>
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
