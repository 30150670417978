import React from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { CREATE_PLACEHOLDER, FEATURED_URL_SETTINGS } from "src/constants";
import { bookingActions } from "src/db/dbActions";
import { reviewRating } from "src/utils";

class ExperienceCard extends React.Component {
  constructor() {
    super();
    this.state = {
      cardImg: "",
      location: "",
    };
  }

  componentDidMount() {
    bookingActions.getBookingByExperience({ id: this.props.experience.id }).then((bookings) => {
      const { count, rating } = reviewRating(bookings.filter((booking) => booking.review !== null));
      this.setState({ avgRating: rating, reviewCount: count });
    });
    this.setState({ experience: this.props.experience, user: this.props.user });
  }

  render() {
    const { experience, user } = this.props;
    const { avgRating, reviewCount } = this.state;
    return (
      <Link
        to={{
          pathname: `/experience/${experience.scout.username}/${experience.kebabTitle}`,
          state: { experience: experience, user: user },
        }}
      >
        <Card className="experience-image">
          <Card.Img
            variant="top"
            className="img-fit-category"
            src={
              experience.images.length > 0
                ? `${FEATURED_URL_SETTINGS}/${
                    experience.images.map((i) =>
                      i
                        .split("/")
                        .slice(i.split("/").length - 5)
                        .join("/")
                    )[0]
                  }`
                : CREATE_PLACEHOLDER
            }
            alt={experience.title}
          />
          <Card.ImgOverlay>
            <Card.Header className="tag-featured experience-tag">
              <p className="featured-tag">Featured</p>
            </Card.Header>
            <Card.Footer className="tag-bottom experience-tag2">
              <p className="experience-header-small">{experience.title}</p>
              <p className="experience-small">
                <FontAwesomeIcon icon={"map-marked-alt"} /> {experience.location.replace(/,/g, ", ")}{" "}
                {avgRating > 0 && reviewCount > 0 ? (
                  <>
                    | <FontAwesomeIcon icon={"star"} /> {parseInt(avgRating).toFixed(1)}
                    <span> ({reviewCount})</span>
                  </>
                ) : (
                  <></>
                )}
              </p>
              <p className="experience-text-small pt-0 mt-0 extra px-2">
                {experience.highlight.length > 25
                  ? `${experience.highlight.substring(0, 25)}...`
                  : experience.highlight}
              </p>
            </Card.Footer>
          </Card.ImgOverlay>
        </Card>
      </Link>
    );
  }
}

export default ExperienceCard;
