import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Layout from "src/components/layout/Layout";
import MetaTags from "src/components/layout/MetaTags";
import { MESSAGES } from "src/constants";
import moment from "moment";

class Post extends React.Component {
  constructor() {
    super();
    this.bookingSub = undefined;
    this.state = {
      messaging: {
        messages: [],
        isMuted: false,
        mutedBy: "",
        mutedDate: null,
        isEdited: false,
        editedBy: "",
        editedDate: null,
        isFlagged: false,
        flaggedBy: "",
        flaggedDate: null,
        createdAt: "",
        updatedAt: "",
      },
      message: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const slug = this.props.location.pathname.slice(5);
    if (slug && !this.props.location.state) {
      fetch(`https://blog.scouted.com/wp-json/wp/v2/posts?_embed&slug=${slug}`)
        .then((response) => response.json())
        .then((post) => {
          this.setState({ post: post[0] });
        });
    } else {
      this.setState({ post: this.props.location.state.post });
    }
  }

  render() {
    const { post } = this.state;
    if (!post) {
      return null;
    }
    return (
      <Layout>
        <MetaTags
          title="Send a message to a Scout"
          description="Use this page to communicate directly with your Scout"
          url={`${window.location.origin}/blog/${post.slug}`}
          image={MESSAGES}
        />
        <Container id="ExperiencePages" className="pt-5">
          <Row className="justify-content-start pt-2">
            <Col xs={12}>
              {post["_embedded"]["wp:term"][0].map((cat, ind) => {
                return (
                  <Button key={ind} disabled variant="secondary" className="me-1">
                    {cat.name}
                  </Button>
                );
              })}
            </Col>
            <Col xs={12}>
              <h1>{post.title.rendered}</h1>
              <p>{`${moment(post.date).format("llll")} by Scouted Media Team`}</p>
            </Col>
            <Col xs={12} className="mb-5">
              <Image src={post["_embedded"][`wp:featuredmedia`][0].source_url} fluid />
            </Col>
            <Col xs={12} dangerouslySetInnerHTML={{ __html: post.content.rendered }} className="mb-5"></Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Post;
