import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import { LOGO_TEXT } from "src/constants";

export default class Loading extends React.Component {
  render() {
    const { className, showMessage = true, loadingMessage } = this.props;

    return (
      <Container className={`justify-content-center ${className}`} style={{ height: "100%" }} fluid>
        <Row className="text-center mt-5">
          <Col xs={12} className="mx-auto" style={{ marginTop: "15vh" }}>
            <Image alt="Scouted LLC Logo" className="img-fluid logo" src={LOGO_TEXT} />
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col xs={12} className="mx-auto">
            <Spinner animation="border" variant="info"></Spinner>
          </Col>
          {showMessage && (
            <Col xs={12} className="pt-3">
              <p>{loadingMessage ? loadingMessage : "Loading... hang tight!"}</p>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}
