/**
 * Determine which environment we are running on and set the appropriate keys
 */
export const CURRENT_VERSION = "9.0.0";
let STRIPE_TOKEN = "";
let GOOGLE_API_KEY = "";
let CONFIG_ID;
if (process.env.NODE_ENV === "production" && window.location.origin === "https://www.scouted.com") {
  CONFIG_ID = "fc91d24b-3698-47ed-8da5-6b30686c148b";
  STRIPE_TOKEN = "pk_live_j23KYwlsDPwb2o568JPJhVOe00vgG5urTN";
  GOOGLE_API_KEY = "AIzaSyA80uLKk4SQ97Cr1h-Bylo9jPetdLSD_2g";
} else if (
  process.env.NODE_ENV === "production" &&
  window.location.origin === "https://demo.dinhij4ec0rxz.amplifyapp.com/"
) {
  CONFIG_ID = "3f4449e2-b3c1-46c9-8f8d-151e4c6be90b";
  STRIPE_TOKEN = "pk_test_UwLDWFkskSOZEX0H6zR81fNB00AvdhvpNc";
  GOOGLE_API_KEY = "AIzaSyA80uLKk4SQ97Cr1h-Bylo9jPetdLSD_2g";
} else {
  CONFIG_ID = "2513005c-f1f4-4e88-b13b-644f4ca243be";
  STRIPE_TOKEN = "pk_test_UwLDWFkskSOZEX0H6zR81fNB00AvdhvpNc";
  GOOGLE_API_KEY = "AIzaSyA80uLKk4SQ97Cr1h-Bylo9jPetdLSD_2g";
}

export { STRIPE_TOKEN, GOOGLE_API_KEY, CONFIG_ID };
