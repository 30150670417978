import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Layout from "../../components/layout/Layout";
import MetaTags from "src/components/layout/MetaTags";
import { MESSAGES } from "src/constants";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";

const api = "https://blog.scouted.com/wp-json/wp/v2/posts?_embed&per_page=5&offset=";

class Posts extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      offset: 1,
      total: 5,
    };
  }

  componentDidMount() {
    const { offset } = this.state;
    window.scrollTo(0, 0);
    fetch(`${api}${offset}`)
      .then((response) => {
        const posts = response.json();
        const total = response.headers.get("X-WP-Total");
        return { posts, total };
      })
      .then((results) => {
        results.posts.then((posts) => this.setState({ posts, total: results.total }));
      });
  }

  render() {
    const { posts, offset, total } = this.state;
    return (
      <Layout>
        <MetaTags
          title="Scouted - Your Ticket To Adventure!"
          description="Just You And Your Phone Is All You Need To Find The Path Less Travelled."
          url={`${window.location.origin}/blog`}
          image={MESSAGES}
        />
        <Container id="following-scouts" className="text-md-start pt-4 bottom-padding">
          <Row className="mb-4">
            <Col xs={12}>
              <h1>Scouted - Your Ticket To Adventure!</h1>
              <h4>Just You And Your Phone Is All You Need To Find The Path Less Travelled.</h4>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2 mb-5">
              {posts &&
                posts.length > 0 &&
                posts.map((post, index) => {
                  return (
                    <Card key={index} className="p-3 my-3">
                      <Row>
                        <Col
                          xs={12}
                          sm={6}
                          md={6}
                          className="my-auto text-center text-sm-center text-md-center text-lg-start"
                        >
                          {/* eslint-disable-next-line */}
                          <a href={`/blog/${post.slug}`} />
                          <Link
                            to={{
                              pathname: `/blog/${post.slug}`,
                              state: { post },
                            }}
                          >
                            <Image src={post["_embedded"][`wp:featuredmedia`][0].source_url} fluid />
                          </Link>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Row>
                            <Link
                              to={{
                                pathname: `/blog/${post.slug}`,
                                state: { post },
                              }}
                            >
                              <h3 className="pt-3">{post.title.rendered}</h3>
                            </Link>
                            <p>{`${moment(post.date).format("llll")} by Scouted Media Team`}</p>
                          </Row>
                          <Row dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></Row>
                          <Row>
                            <Link
                              to={{
                                pathname: `/blog/${post.slug}`,
                                state: { post },
                              }}
                            >
                              Read More
                            </Link>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
            </Col>
            <Col xs={12} className="text-start py-2 ps-0">
              <Pagination className="justify-content-center">
                <Pagination.Prev
                  onClick={() => {
                    if (offset === 1 ) {
                      return;
                    }
                    let current = offset - 5 > 1 ? offset - 5 : 1;
                    fetch(`${api}${current}`)
                      .then((response) => response.json())
                      .then((posts) => this.setState({ posts: posts, offset: current }));
                  }}
                />
                <Pagination.Next
                  onClick={() => {
                    let current = offset + 5;
                    if (current >= total) {
                      return;
                    }
                    fetch(`${api}${current}`)
                      .then((response) => response.json())
                      .then((posts) =>
                        posts.length > 0
                          ? this.setState({ posts: posts, offset: current })
                          : this.setState({ posts: posts, offset: offset })
                      );
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Posts;
