import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "src/styles/CustomSlider.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, width: 35, height: 33, borderRadius: "1rem" }} onClick={onClick} />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, width: 35, height: 33, borderRadius: "1rem" }} onClick={onClick} />
  );
};

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  className: "slides",

  customPaging: (slick, i) => {
    return (
      <div
        key={i}
        style={{
          width: "50px",
          padding: "15px",
          color: "blue",
        }}
      >
        <FontAwesomeIcon icon={"dot-circle"} aria-hidden="true" className="grey" />
      </div>
    );
  },

  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class CustomSlider extends React.Component {
  render() {
    return <Slider {...settings}>{this.props.children}</Slider>;
  }
}

export default CustomSlider;
